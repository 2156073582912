import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Navbar, Nav, Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import './Footer.css'

const Footer = () => {
    const { t, i18n } = useTranslation();
    return (
        <section className="lemonboxfooter">
            <footer className="container py-5">
            <div className="row">
                    <div className="col-12 col-md"><img
                            alt=""
                            src="/img/lemonboxlogogray.png"
                            height="24"
                            className="d-inline-block align-top"
                        />
                        <small className="d-block mb-3 text-muted">&copy; 2019</small>
                    </div>
                    <div className="col-6 col-md">
                        <h5>{t("footer.contact")}</h5>
                        <ul className="list-unstyled text-small">
                            <li><a className="text-muted" href="mailto:victorvoorhuis@gmail.com?subject=Lemonbox%20Business">{t("footer.businessRelated")}</a></li>
                            <li><a className="text-muted" href=" mailto:victorvoorhuis@gmail.com?subject=Lemonbox%20Tech">{t("footer.techRelated")}</a></li>
                        </ul>
                    </div>
                </div>
            </footer>
        </section>
    )
}

export default Footer;