import React from 'react';
import './App.css';
import { BrowserRouter, Switch, Route, Link } from 'react-router-dom';
import Navbar from './components/layout/Navbar'
import HomeScreen from './components/homeScreen/HomeScreen'
import Footer from './components/layout/Footer'

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Navbar />
        <Switch>
          <Route exact path='/' component={HomeScreen} />
        </Switch>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
