import React, {Component} from 'react';
import Container from 'react-bootstrap/Container';
import './HomeScreen.css'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next';

class HomeScreen extends Component {
    render() {
        const { t } = this.props;
        return (
            <>
                <section className="lemonboxwelcome">
                    <div className="logocontainer">
                        <img src="img/lemonboxlogo.png" className="lemonboxicon" />
                    </div>
                </section>

                <section className="jumbotron text-center">
                    <div className="container">
                        <h1 className="jumbotron-heading">{t('welcome.title')}</h1>
                        <p className="lead text-muted">{t('welcome.subtitle')}</p>
                        <p>
                            <Link className="btn btn-outline-secondary" href="#">{t('welcome.comingSoon')}</Link>
                        </p>
                    </div>
                </section>

            </>
        )
    }
}

export default withTranslation()(HomeScreen);