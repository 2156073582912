import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Navbar, Nav, Container } from 'react-bootstrap'

const LemonboxNavbar = () => {
    return (
        <div>
            <Navbar variant="dark" className="timsiNavbar">
                <Container>
                    <Link to='/' className="brand-logo">
                        <Navbar.Brand>
                            <img
                                alt=""
                                src="/img/lemonboxlogo.png"
                                height="50"
                                className="d-inline-block align-top"
                            />
                        </Navbar.Brand>
                    </Link>
                    {/*<Nav className="mr-auto">
                        <Nav.Link style={{color: '#fff'}}>Features</Nav.Link>
                        <Nav.Link style={{color: '#fff'}}>Pricing</Nav.Link>
                        <Nav.Link style={{color: '#fff'}}>Sign in</Nav.Link>
                    </Nav>*/}
                </Container>
            </Navbar>
        </div>
    )
}

export default LemonboxNavbar